<template>
    <div>
        <h4 class="tit tit--sm font-weight-medium mb-8 mb-lg-16">댓글 <span class="primary--text">{{ totalCount }}</span></h4>
        <div class="board__comment">
            <div class="board__comment__row grey-f7">
                <v-row class="row--x-small" align="center">
                    <v-col>
                        <v-text-field v-model="comment.content" placeholder="댓글을 입력하세요." outlined hide-details @input="isRecomment = false" />
                    </v-col>
                    <v-col cols="auto">
                        <v-btn small text color="grey-1" @click="save">
                            등록하기
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-for="comment in bestComments" :key="comment._id">
                <board-best-comment-item :comment="comment" @recomment="init"/>
            </div>
            <div v-for="comment in comments" :key="comment._id">
                <board-comment-item :comment="comment" @recomments="recomments" @recomment="init"/>
            </div>
        </div>
        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="init"></v-pagination>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import BoardCommentItem from "@/components/client/board/board-comment-item.vue";
import BoardBestCommentItem from "@/components/client/board/board-best-comment-item.vue";

export default {
    components: {
        BoardCommentItem,
        BoardBestCommentItem
    },
    props: {},
    async mounted() {
        await this.init();
    },
    data() {
        return {
            page: 1,
            limit: 10,
            pageCount: 0,
            comments: [],
            bestComments: [],
            comment: {
                _board: this._board,
                content: null,
            },
            
            recomment: {
                _board: this._board,
                _parents: [],
                content: null,
            },
            parent: null,
            isRecomment: false,

            totalCount: null
        };
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            let { summary, comments } = await api.v1.boards.comments.gets(this._board, {
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: { depth: 1 }
            })
            this.totalCount = summary.totalCount
            this.pageCount = Math.ceil(summary.pageCount / this.limit);
            this.comments = comments;

            let { comments: bestComments } = await api.v1.boards.comments.bestCommnets(this._board)
            this.bestComments = bestComments;

        },
        async save() {
            if(this.logon) {
                this.comment.depth = 1
                await api.v1.boards.comments.post(this._board , this.comment)
                this.comment.content = null
                alert("댓글이 등록되었습니다.")
                this.$emit("comment")
                this.init();
            } else {
                alert("댓글은 회원만 작성 가능합니다")
            }
        },
        recomments(parent) {
            this.parent = parent
            this.isRecomment = true

        }
    },
    computed: {
        _board() {
            return this.$route.params._board;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload || {};
        },
        logon() {
            return !!this.accessToken;
        }
    },
    watch: {
        isRecomment() {
            if(!this.isRecomment) {
                this.recomment = {
                _board: this._board,
                _parents: null,
                _children: null,
                content: null,
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
