<template>
    <div>
        <div class="board__view mb-40 mb-lg-60">
            <div class="board__view__row">
                <v-row class="row--x-small" align-md="center" justify-md="space-between">
                    <v-col cols="12" md="">
                        <v-row class="row--x-small">
                            <v-col cols="auto">
                                <v-chip color="primary-50" text-color="primary">{{ board?.category?.name }}</v-chip>
                            </v-col>
                            <v-col>
                                <p class="page-text font-weight-medium">
                                    {{ board?.subject }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <div class="d-flex justify-end align-center">
                            <i class="icon icon-clock mr-4 mr-lg-8"></i>
                            <p class="page-text page-text--sm grey-9--text">
                                {{ $dayjs(board?.createdAt).format("YYYY.MM.DD") }}
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="board__view__row">
                <board-count-view :viewCount="board?.viewCount" justifyEnd />
            </div>
            <div class="board__view__row board__contents">
                <div v-html="board?.content" class="board__content mb-20 mb-lg-40"></div>
            </div>
        </div>

        <board-comment @comment="comment"/>
    </div>
</template>

<script>
import api from "@/api";
import BoardTit from "@/components/client/board/board-item__tit.vue";
import BoardCountView from "@/components/client/board/board-count--view.vue";
import BoardComment from "@/components/client/board/board-comment.vue";

export default {
    components: {
        BoardTit,
        BoardCountView,
        BoardComment,
    },
    async created() {
        await this.init();
    },
    data() {
        return {
            board: null,
        };
    },
    methods: {
        async init() {
            let { board } = await api.v1.boards.get({ _id: this._board });
            let youtubeContent = this.replaceAllYouTubeLinks(board.content)
            youtubeContent = this.replaceYouTubeLink(youtubeContent)
            board.content = youtubeContent
            this.board = board;
        },
        async edit() {},
        async delete() {},
        async like() {
            let { board } = await api.v1.boards.like({ _id: this.board._id });
            this.board = board;
        },
        async disLike() {
            let { board } = await api.v1.boards.disLike({ _id: this.board._id });
            this.board = board;
        },
        async comment() {
            await this.init();
        },
        replaceAllYouTubeLinks(str) {
            // 검색할 문자열
            const searchStr = "https://www.youtube.com/watch?v=";

            // <a> 태그와 </a> 태그 사이의 문자열을 검색하여 추출함
            const regex = /<a\b[^>]*>(.*?)<\/a>/gi;
            let matchedStr = regex.exec(str);
            let newStr = str;

            // 문자열에서 검색 대상 문자열이 포함되어 있을 때까지 반복함
            while (matchedStr) {
                // 검색 대상 문자열에서 검색할 문자열의 위치를 찾음
                const startIndex = matchedStr[0].indexOf(searchStr);
                if (startIndex !== -1) {
                const endIndex = startIndex + searchStr.length;
                const videoId = matchedStr[0].substring(endIndex, endIndex + 11);
                // 11자리 YouTube 동영상 ID 값을 추출함

                // iframe 태그를 생성하고 YouTube 동영상 ID 값을 삽입함
                const iframeHtml = '<div class="iframe"><div class="iframe__inner"><iframe src="https://www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe></div></div>';

                // 검색 대상 문자열에서 <a> 태그와 </a> 태그 사이의 문자열을 iframe 태그로 교체함
                newStr = newStr.replace(matchedStr[0], iframeHtml);
                }
                matchedStr = regex.exec(str);
            }

            return newStr;
        },
        replaceYouTubeLink(str) {
            // 검색할 문자열
            const searchStr = "https://youtu.be/";

            // <a> 태그와 </a> 태그 사이의 문자열을 검색하여 추출함
            const regex = /<a\b[^>]*>(.*?)<\/a>/gi;
            let matchedStr = regex.exec(str);
            let newStr = str;

            // 문자열에서 검색 대상 문자열이 포함되어 있을 때까지 반복함
            while (matchedStr) {
                // 검색 대상 문자열에서 검색할 문자열의 위치를 찾음
                const startIndex = matchedStr[0].indexOf(searchStr);
                if (startIndex !== -1) {
                const videoId = matchedStr[0].substring(startIndex + searchStr.length, startIndex + searchStr.length + 11);
                // iframe 태그를 생성하고 동영상 ID 값을 삽입함
                const iframeHtml = '<div class="iframe"><div class="iframe__inner"><iframe src="https://www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe></div></div>';

                // 검색 대상 문자열에서 <a> 태그와 </a> 태그 사이의 문자열을 iframe 태그로 교체함
                newStr = newStr.replace(matchedStr[0], iframeHtml);
                }
                matchedStr = regex.exec(str);
            }

            return newStr;
        }
    },
    computed: {
        _board() {
            return this.$route.params._board;
        },
    },
};
</script>

<style lang="scss" scoped></style>
