var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "tit tit--sm font-weight-medium mb-8 mb-lg-16"
  }, [_vm._v("댓글 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.totalCount))])]), _c('div', {
    staticClass: "board__comment"
  }, [_c('div', {
    staticClass: "board__comment__row grey-f7"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "placeholder": "댓글을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.isRecomment = false;
      }
    },
    model: {
      value: _vm.comment.content,
      callback: function ($$v) {
        _vm.$set(_vm.comment, "content", $$v);
      },
      expression: "comment.content"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "color": "grey-1"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 등록하기 ")])], 1)], 1)], 1), _vm._l(_vm.bestComments, function (comment) {
    return _c('div', {
      key: comment._id
    }, [_c('board-best-comment-item', {
      attrs: {
        "comment": comment
      },
      on: {
        "recomment": _vm.init
      }
    })], 1);
  }), _vm._l(_vm.comments, function (comment) {
    return _c('div', {
      key: comment._id
    }, [_c('board-comment-item', {
      attrs: {
        "comment": comment
      },
      on: {
        "recomments": _vm.recomments,
        "recomment": _vm.init
      }
    })], 1);
  })], 2), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.init
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }