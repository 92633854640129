<template>

    <!-- S::베스트댓글 -->
    <div class="board__comment__row board__comment-item primary-50">
        <v-row class="row--x-small">
            <v-col cols="12" md="auto">
                <v-row class="ma-n2" align="center">
                    <v-col cols="auto" class="pa-2">
                        <span class="chip--best">
                            <i class="icon icon-star mr-4"></i>
                            BEST
                        </span>
                    </v-col>
                    <v-col cols="auto" class="pa-2 d-flex align-center">
                        <board-user-level :user="comment?.user" textStyle="page-text font-weight-medium" />
                    </v-col>
                    <v-col cols="auto" class="pa-2">
                        <p class="page-text page-text--sm grey-9--text">
                            {{ $dayjs(comment?.createdAt)?.format("YYYY.MM.DD HH:mm:ss") }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="auto">
                <v-row class="row--x-small page-text page-text--sm grey-9--text" align="center">
                    <!-- <v-col cols="auto">
                        <router-link to="" class="d-flex align-center">
                            <i class="icon icon-top-right-arrow mr-4"></i>
                            <p>
                                댓글로가기
                            </p>
                        </router-link>
                    </v-col> -->
                    <!-- <v-col cols="auto">
                        ·
                    </v-col> -->
                    <v-col cols="auto">
                        <button class="d-flex align-center" @click="like">
                            <i class="icon icon-recommend-grey mr-4"></i>
                            <p>
                                {{ comment?.likeCount }}
                            </p>
                        </button>
                    </v-col>
                    <v-col cols="auto">
                        ·
                    </v-col>
                    <v-col cols="auto">
                        <button class="d-flex align-center" @click="disLike">
                            <i class="icon icon-opposition-grey mr-4"></i>
                            <p>
                                {{ comment?.dislikeCount }}
                            </p>
                        </button>
                    </v-col>
                    <!-- S::내가 작성한 댓글일 때 노출 -->
                    <v-col v-if="payload._user === comment._user && !comment.isDelete" cols="auto">
                        ·
                    </v-col>                        
                    <v-col v-if="payload._user === comment._user && !comment.isDelete" cols="auto">
                        <button class="d-flex align-center" @click="editComment = comment; isEditcomment = true">
                            <i class="icon icon-edit mr-4"></i>
                            <p>
                                수정
                            </p>
                        </button>
                    </v-col>
                    <v-col v-if="payload._user === comment._user && !comment.isDelete" cols="auto">
                        ·
                    </v-col>
                    <v-col v-if="payload._user === comment._user && !comment.isDelete" cols="auto">
                        <button class="d-flex align-center" @click="deleted(comment)">
                            <i class="icon icon-delete mr-4"></i>
                            <p>
                                삭제
                            </p>
                        </button>
                    </v-col>
                    <!-- E::내가 작성한 댓글일 때 노출 -->
                </v-row>
            </v-col>
        </v-row>
        <p class="page-text page-text--sm grey-6--text mt-12 mt-md-8">
            {{ comment?.content }}
        </p>
    </div>
    <!-- E::베스트댓글 -->

</template>

<script>
import api from "@/api"
import BoardUserLevel from "@/components/client/board/board-user-level.vue";

export default {
    name: "CommentItem",
    components: {
        BoardUserLevel,
    },
    props: { 
        best: { type: Boolean, default: false },
        depth: { type: String, default: null },
        comment: { type: Object, default: null }
    },
    mounted() {

    },
    data() {
        return {
            recomment: {
                _board: this._board,
                _parents: [],
                content: null,
            },
            parent: {
                _id: null
            },
            isRecomment: false,
            isEditcomment: false,
            editComment: {
                _id: null
            }
        };
    },
    methods: {
        async save() {
            if(this.logon) {
                this.recomment.depth = parseInt(this.parent.depth + 1)
                this.recomment._parents.push(parent._id)
    
                let { comment } = await api.v1.boards.comments.post(this._board , this.recomment)
                this.parent._children.push(comment._id)
    
                await api.v1.boards.comments.put(this._board, {...this.parent, code: "child"})
                this.$emit("recomment")
                this.isRecomment = false
                alert("저장되었습니다")
            } else {
                alert("댓글은 회원만 작성 가능합니다")
            }
        },
        async edit() {
            await api.v1.boards.comments.put(this._board, this.editComment)
            this.isEditcomment = false
            this.$emit("recomment")
            alert("수정되었습니다")
        },
        async deleted(comment) {
            await api.v1.boards.comments.delete(this._board, comment)
            this.$emit("recomment")
            alert("삭제되었습니다")
        },
        async like() {
            let { comment } = await api.v1.boards.comments.like(this._board, this.comment);
            this.$emit("recomment")
            this.comment = comment;
        },
        async disLike() {
            let { comment } = await api.v1.boards.comments.disLike(this._board, this.comment);
            this.$emit("recomment")
            this.comment = comment;
        },
    },
    computed: {
        _board() {
            return this.$route.params._board;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload || {};
        },
        logon() {
            return !!this.accessToken;
        },
        _user() {
            return this.payload._user;
        },
    },
};
</script>

<style lang="scss" scoped>
.chip--best{
    display: flex;
    align-items: center;
    height: 24px;
    background-color: #fff;
    border-radius: 6px;
    padding: 0 10px;
    font-size: 1.2rem;
    color: var(--v-primary-base);
    width: fit-content;
}
</style>
