var render = function render(){
  var _vm$comment, _vm$comment2, _vm$comment3, _vm$comment4, _vm$comment5, _vm$comment6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board__comment__row board__comment-item"
  }, [_c('div', {
    class: 'w-100 pl-' + 12 * _vm.depth + ' pl-lg-' + 40 * _vm.depth
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2 d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-user-level', {
    attrs: {
      "user": (_vm$comment = _vm.comment) === null || _vm$comment === void 0 ? void 0 : _vm$comment.user,
      "textStyle": "page-text font-weight-medium"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs((_vm$comment2 = _vm.comment) === null || _vm$comment2 === void 0 ? void 0 : _vm$comment2.createdAt).format("YYYY.MM.DD HH:mm:ss")) + " ")])])], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small page-text page-text--sm grey-9--text",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": _vm.like
    }
  }, [_c('i', {
    staticClass: "icon icon-recommend-primary mr-4"
  }), _c('p', [_vm._v(" " + _vm._s((_vm$comment3 = _vm.comment) === null || _vm$comment3 === void 0 ? void 0 : _vm$comment3.likeCount) + " ")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": _vm.disLike
    }
  }, [_c('i', {
    staticClass: "icon icon-opposition-grey mr-4"
  }), _c('p', [_vm._v(" " + _vm._s((_vm$comment4 = _vm.comment) === null || _vm$comment4 === void 0 ? void 0 : _vm$comment4.dislikeCount) + " ")])])]), _vm.payload._user === _vm.comment._user && !_vm.comment.isDelete ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]) : _vm._e(), _vm.payload._user === _vm.comment._user && !_vm.comment.isDelete ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": function ($event) {
        _vm.editComment = _vm.comment;
        _vm.isEditcomment = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon icon-edit mr-4"
  }), _c('p', [_vm._v(" 수정 ")])])]) : _vm._e(), _vm.payload._user === _vm.comment._user && !_vm.comment.isDelete ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]) : _vm._e(), _vm.payload._user === _vm.comment._user && !_vm.comment.isDelete ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": function ($event) {
        return _vm.deleted(_vm.comment);
      }
    }
  }, [_c('i', {
    staticClass: "icon icon-delete mr-4"
  }), _c('p', [_vm._v(" 삭제 ")])])]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": function ($event) {
        _vm.parent = _vm.comment;
        _vm.isRecomment = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon icon-comment mr-4"
  }), _c('p', [_vm._v(" 댓글 ")])])])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-12 mt-md-8"
  }, [_vm.depth ? _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-comment-arrow"
  })]), _c('v-col', [_c('p', {
    staticClass: "page-text page-text--sm grey-6--text"
  }, [_vm._v(" " + _vm._s((_vm$comment5 = _vm.comment) === null || _vm$comment5 === void 0 ? void 0 : _vm$comment5.content) + " ")])])], 1) : _c('p', {
    staticClass: "page-text page-text--sm grey-6--text"
  }, [_vm._v(" " + _vm._s((_vm$comment6 = _vm.comment) === null || _vm$comment6 === void 0 ? void 0 : _vm$comment6.content) + " ")])], 1)], 1)]), _vm.comment._id === _vm.editComment._id && _vm.isEditcomment ? _c('div', {
    staticClass: "board__comment__row grey-f7"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "placeholder": "댓글을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editComment.content,
      callback: function ($$v) {
        _vm.$set(_vm.editComment, "content", $$v);
      },
      expression: "editComment.content"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "color": "grey-1"
    },
    on: {
      "click": _vm.edit
    }
  }, [_vm._v(" 수정하기 ")])], 1)], 1)], 1) : _vm._e(), _vm.comment._id === _vm.parent._id && _vm.isRecomment ? _c('div', {
    staticClass: "board__comment__row grey-f7"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "placeholder": "댓글을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.recomment.content,
      callback: function ($$v) {
        _vm.$set(_vm.recomment, "content", $$v);
      },
      expression: "recomment.content"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "color": "grey-1"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 등록하기 ")])], 1)], 1)], 1) : _vm._e(), _c('div', _vm._l(_vm.comment.children, function (children) {
    return _c('comment-item', {
      key: children._id,
      attrs: {
        "comment": children,
        "depth": `${children.depth - 1}`
      },
      on: {
        "recomment": function ($event) {
          return _vm.$emit('recomment');
        }
      }
    });
  }), 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }