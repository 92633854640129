var render = function render(){
  var _vm$comment, _vm$$dayjs, _vm$comment2, _vm$comment3, _vm$comment4, _vm$comment5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__comment__row board__comment-item primary-50"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "chip--best"
  }, [_c('i', {
    staticClass: "icon icon-star mr-4"
  }), _vm._v(" BEST ")])]), _c('v-col', {
    staticClass: "pa-2 d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-user-level', {
    attrs: {
      "user": (_vm$comment = _vm.comment) === null || _vm$comment === void 0 ? void 0 : _vm$comment.user,
      "textStyle": "page-text font-weight-medium"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s((_vm$$dayjs = _vm.$dayjs((_vm$comment2 = _vm.comment) === null || _vm$comment2 === void 0 ? void 0 : _vm$comment2.createdAt)) === null || _vm$$dayjs === void 0 ? void 0 : _vm$$dayjs.format("YYYY.MM.DD HH:mm:ss")) + " ")])])], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small page-text page-text--sm grey-9--text",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": _vm.like
    }
  }, [_c('i', {
    staticClass: "icon icon-recommend-grey mr-4"
  }), _c('p', [_vm._v(" " + _vm._s((_vm$comment3 = _vm.comment) === null || _vm$comment3 === void 0 ? void 0 : _vm$comment3.likeCount) + " ")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": _vm.disLike
    }
  }, [_c('i', {
    staticClass: "icon icon-opposition-grey mr-4"
  }), _c('p', [_vm._v(" " + _vm._s((_vm$comment4 = _vm.comment) === null || _vm$comment4 === void 0 ? void 0 : _vm$comment4.dislikeCount) + " ")])])]), _vm.payload._user === _vm.comment._user && !_vm.comment.isDelete ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]) : _vm._e(), _vm.payload._user === _vm.comment._user && !_vm.comment.isDelete ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": function ($event) {
        _vm.editComment = _vm.comment;
        _vm.isEditcomment = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon icon-edit mr-4"
  }), _c('p', [_vm._v(" 수정 ")])])]) : _vm._e(), _vm.payload._user === _vm.comment._user && !_vm.comment.isDelete ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]) : _vm._e(), _vm.payload._user === _vm.comment._user && !_vm.comment.isDelete ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "d-flex align-center",
    on: {
      "click": function ($event) {
        return _vm.deleted(_vm.comment);
      }
    }
  }, [_c('i', {
    staticClass: "icon icon-delete mr-4"
  }), _c('p', [_vm._v(" 삭제 ")])])]) : _vm._e()], 1)], 1)], 1), _c('p', {
    staticClass: "page-text page-text--sm grey-6--text mt-12 mt-md-8"
  }, [_vm._v(" " + _vm._s((_vm$comment5 = _vm.comment) === null || _vm$comment5 === void 0 ? void 0 : _vm$comment5.content) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }